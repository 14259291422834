<template>
  <PageLayout>
    <div class="reset-password-page">
      <div class="reset-password-content">
        <h1 class="header">Reset Password</h1>
        <template v-if="!isVerifiedCode">
          <div class="verifying-text">
            Loading...
          </div>
        </template>
        <template v-if="isVerifiedCode && !isResetSuccess && !codeIsValid">
          <div class="verifying-failed">
            <p>Your request is invalid or token already expired.</p>
            <p>Please request to reset password again.</p>
          </div>
        </template>
        <template v-if="isVerifiedCode && !isResetSuccess && codeIsValid">
          <p>Please enter and confirm your new password below.</p>
          <form @submit.prevent="onSubmit">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                placeholder="Your new password"
                v-model="password"
                @change="onPasswordChange"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                placeholder="Repeat your new password "
                v-model="confirm"
                @change="onPasswordChange"
              />
            </div>
            <div class="text-danger" v-if="passwordNotMatch">
              Passwords don't match
            </div>
            <div class="text-danger" v-if="passwordInvalid">
              <p>Passwords must</p>
              <ul>
                <li>Has at lease 8 characters</li>
                <li>Contains at lease one uppercase character.</li>
                <li>Contains at lease one lowercase character.</li>
                <li>Contains at lease one digit.</li>
                <li>Contains at lease one symbols.</li>
              </ul>
            </div>
            <div
              class="text-danger reset-password-error-msg"
              v-if="resetPasswordFailed"
            >
              Something went wrong.
            </div>
            <div class="form-actions">
              <input
                type="submit"
                class="form-control"
                value="Reset Password"
                :disabled="!isPasswordValid"
              />
            </div>
          </form>
        </template>
        <template v-if="isResetSuccess">
          <div class="success-message">
            <p>
              Your password has been reset. You can log into your account in the
              Mongolia travel mobile application again.
            </p>
            <p>You will be forwarded to the Mongolia.travel homepage ...</p>
          </div>
        </template>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import PasswordValidator from "password-validator";
import axios from "axios";

export default {
  name: "ResetPassword",
  components: {
    PageLayout,
  },
  data() {
    return {
      isVerifiedCode: false,
      schema: null,
      password: "",
      confirm: "",
      codeIsValid: true,
      isResetSuccess: false,
      passwordNotMatch: false,
      passwordInvalid: false,
      resetPasswordFailed: false,
    };
  },
  mounted() {
    const schema = new PasswordValidator();

    schema
      .is()
      .min(8)
      .is()
      .max(100)
      .has()
      .uppercase()
      .has()
      .lowercase()
      .has()
      .digits()
      .has()
      .not()
      .spaces();

    this.schema = schema;

    this.verifyCode();
  },
  computed: {
    isPasswordValid() {
      return !this.passwordNotMatch && !this.passwordInvalid;
    },
  },
  methods: {
    verifyCode() {
      const postData = {
        query: `
        query verify($code: String!) {
            verifyAppUserResetPasswordCode(code: $code)
        }
        `,
        variables: {
          code: this.$route.query.code,
        },
      };
      axios
        .post(process.env.VUE_APP_API_URI, postData, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY,
          },
        })
        .then((res) => {
          this.codeIsValid = res.data.data.verifyAppUserResetPasswordCode;
          this.isVerifiedCode = true;
        })
        .catch(console.error);
    },
    valdiatePasswords() {
      this.passwordInvalid = !this.schema.validate(this.password);
      this.passwordNotMatch = this.password !== this.confirm;
    },
    onPasswordChange() {
      this.valdiatePasswords();
    },
    onSubmit() {
      this.resetPasswordFailed = false;

      if (this.isPasswordValid) {
        this.updatePassword();
      }
    },
    updatePassword() {
      const postData = {
        query: `
        mutation resetAppUserPassword($password: String!, $code: String!) {
            resetAppUserPassword(password: $password, code: $code)
        }
        `,
        variables: {
          password: this.password,
          code: this.$route.query.code,
        },
      };
      axios
        .post(process.env.VUE_APP_API_URI, postData, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY,
          },
        })
        .then((res) => {
          this.isResetSuccess = res.data.data.resetAppUserPassword === true;
          if (this.isResetSuccess) {
            setTimeout(() => {
              this.$router.push("/");
            }, 5000);
          } else {
            this.resetPasswordFailed = true;
          }
        })
        .catch(console.log);
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password-page {
  text-align: center;
  position: relative;
  width: 100%;
  height: 600px;

  .text-danger {
    text-align: left;
    margin: 1em 0;
    font-size: 0.9rem;

    p {
      margin-bottom: 0.5em;
    }

    ul {
      padding-left: 25px;
    }
  }

  .reset-password-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .header {
    font-family: Poppins, "sans-serif";
    font-size: 3.75rem;
    font-weight: bold;
    text-align: center;
  }

  form {
    max-width: 550px;
    margin: 0 auto;

    input[type="password"] {
      border-radius: 13px;
      padding: 24px;
    }

    input[type="submit"] {
      background-color: #228b5b;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      min-height: 50px;
      border-radius: 13px;
    }
  }

  .success-message {
    margin-top: 30px;
  }
}
</style>
